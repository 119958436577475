import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionBecomeMentorTop.module.css';

import inlineImage from './images/become-a-mentor-small.png';

const SectionBecomeMentorTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h1 className={css.stepTitle}>
                  Become a Mentor
                </h1>

                <h2 className={css.stepSubSubTitle}>
                  Key Features
                </h2>

                <ul className={css.diskList}>
                  <li>
                    <p className={css.stepText}>
                      Anyone can become a mentor - from career starter to business owner
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Open to all industries, sectors and career paths
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      The perfect side hustle that works around you
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Complete flexibility around your schedule
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Inspire and develop the next generation in your career path
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Grow your own network and develop job specific skills
                    </p>
                  </li>
                </ul>


                <p className={css.stepText}>
                  No matter your career path, sector, or industry, getting onto and progressing up the career
                  ladder is more daunting than ever. Whether your professional journey has been a hard fought
                  challenge or comes relatively easily, you’ll have no doubt learned many lessons that can
                  help others expedite their careers. This is the role of the career mentor.
                </p>
                <p className={css.stepText}>
                  However, choosing to become a career mentor isn’t just about guiding others,
                  it can also have a profound impact on your own self-discovery and leadership skills.
                  As a career mentor, you'll be doing so much more than just sharing knowledge, you’ll be
                  building a growth mindset, building resilience and nurturing the next generation
                  in your given field.
                </p>
                <p className={css.stepText}>
                  At Career Navig8r, our mentorship platform allows you to shape careers and empower
                  individuals to choose their ideal professional paths. Our resources, tools and
                  Navigator program will equip you to make a meaningful difference in the professional
                  lives of others.
                </p>

                <p className={css.stepText}>
                  When you become a mentor, you’ll be given the opportunity to:
                </p>

                <ul className={css.diskList}>
                  <li>
                    <p className={css.stepText}>
                      Nurture talent for the next generation in your field
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Guide potential future leaders
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Inspire ambitious minds
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Build your own leadership skills
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Leave a lasting legacy within your space
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Get paid for giving back
                    </p>
                  </li>
                </ul>

                <p className={css.stepText}>
                  If you want to apply to be a mentor and join our community of Navig8rs, sign up with our mentorship platform today!
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage1"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentor',
                    }}
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Become a mentor"
                  >
                    Become a mentor
                  </NamedLink>
                </div>


                <h2 className={css.stepSubSubTitle}>
                  What Does Career Mentorship Involve?
                </h2>

                <p className={css.stepText}>
                  Career mentorship is a dynamic relationship built on the specific needs of the mentee.
                  However, a typical relationship will focus on guidance, support and mutual career growth.
                </p>
                <p className={css.stepText}>
                  At its core, choosing to become a mentor allows you to share your knowledge,
                  experiences and insights from your particular industry to help others who are
                  either looking to begin their professional journey in the same job role/field
                  or advance further up the career ladder.
                </p>
                <p className={css.stepText}>
                  When you become a career mentor, you get to create an environment tailored
                  to the personality of your mentee. That space will be supportive, challenging
                  and encouraging to ensure that your mentee is comfortable discussing their aspirations,
                  challenges and goals openly.
                </p>
                <p className={css.stepText}>
                  As an online mentor, you then provide guidance tailored to their individual
                  needs, offering advice on skill development, networking, work-life balance and
                  any specific issues they will face.
                </p>
                <p className={css.stepText}>
                  Before you become a mentor, keep in mind that it’s a two-way street.
                  Whilst an excellent way to make a side income, you’ll also learn from your
                  mentees’ perspectives, experiences and fresh ideas. A Navig8r mentor and mentee
                  relationship is built to fuel the personal and professional development of both parties.
                </p>
                <p className={css.stepText}>
                  Effective career mentorship also includes setting and achieving clear, definable goals.
                  As an online mentor, you will assist your mentees in setting realistic,
                  achievable objectives while providing the necessary tools and encouragement to reach them.
                  You’ll also offer constructive feedback to help them identify their
                  strengths and areas to focus on.
                </p>
                <p className={css.stepText}>
                  However, it doesn’t end there. When you become a career mentor, you may
                  want to introduce your mentees to valuable connections, helping expand
                  their professional network. This networking can open doors to new opportunities,
                  collaborations and further career advancement.
                </p>
                <p className={css.stepText}>
                  Ultimately, career mentorship is about dedication and a genuine commitment
                  to the growth and success of your mentee. It’s about being a guide, a
                  motivator and a supporter, steering people towards fulfilling and
                  rewarding career paths.
                </p>
                <p className={css.stepText}>
                  This relationship isn’t just about giving advice, it’s also about
                  pushing potential, confidence and excellence.
                </p>





                <h2 className={css.stepSubSubTitle}>
                  Why Become A Career Mentor?
                </h2>
                <p className={css.stepText}>
                  Becoming a career mentor is rewarding in many ways. It’s a chance to
                  impart your knowledge and experience, guide professionals in your field,
                  and leave a mark on the trajectory of their careers.
                </p>
                <p className={css.stepText}>
                  However, beyond giving back, online mentorship also offers you a platform
                  for personal growth, leadership enhancement, and the opportunity to shape
                  the future of your industry. You’ll be helping others excel in their professional
                  lives whilst opening up a lucrative side income opportunity.
                </p>


                <h3>
                  Empower the Next Generation of Professionals
                </h3>

                <p className={css.stepText}>
                  When you apply to be a mentor, you are continuing your legacy within your industry,
                  helping those on their journey upwards. Whilst many of our career experiences
                  may feel mundane to us now, that hard-fought wisdom is bound to leave a memorable
                  impact on your mentees.
                </p>
                <p className={css.stepText}>
                  Share your insights, nurture their strengths, and give them the confidence needed
                  to deal with career challenges. As an online mentor, you can inspire your
                  mentees to better understand their potential and take bold steps.
                </p>
                <p className={css.stepText}>
                  The impact of this goes beyond individual growth—it pushes your industry and
                  your sector forward and ensures a legacy of capable professionals who can make
                  a meaningful difference in the world.
                </p>

                <h3>
                  Share Your Expertise & Shape Careers
                </h3>

                <p className={css.stepText}>
                  As a professional in your field, you’ve likely gained years of experience
                  and have an in-depth understanding of the ins and outs of your specific
                  industry. Similarly, you’ve probably come across challenges that some
                  are struggling with now.
                </p>
                <p className={css.stepText}>
                  Our online mentorship platform allows you to share your expertise
                  and leave a meaningful mark. The lessons from your experience and
                  career-path-specific insights help other aspiring professionals.
                  You can provide invaluable guidance that enables your mentees to
                  make informed decisions and seize opportunities.
                </p>
                <p className={css.stepText}>
                  When you become a mentor, you sculpt their careers, influence
                  their pivotal choices, and foster growth in their professional lives.
                  Your guidance doesn’t just offer practical advice, it also inspires
                  confidence and broadens their perspectives.
                </p>
                <p className={css.stepText}>
                  When you apply to be a mentor, the impact of your shared
                  expertise will also help your mentees in their
                  future professional endeavours.
                </p>

                <h3>
                  Enhance Your Leadership Skills
                </h3>

                <p className={css.stepText}>
                  Online mentorship also offers a great way to refine and amplify your
                  leadership skills. When you become a mentor, you're not just guiding,
                  you're leading by example. A successful mentor will have a combination
                  of empathy, communication, adaptability and strategic thinking.
                </p>
                <p className={css.stepText}>
                  By guiding others through their professional journeys, you can fine-tune
                  your own ability to inspire, motivate and influence positively.
                  Moreover, when you become a mentor, you’ll also be able to sharpen
                  your capacity to listen attentively, understand diverse perspectives,
                  and offer the right guidance.
                </p>
                <p className={css.stepText}>
                  The process of mentoring gives you real-time opportunities to practise
                  your decision-making, delegation and conflict resolution, all of which
                  are fundamental aspects of effective leadership. When you become a career
                  mentor, you gain a deeper understanding of interpersonal dynamics and team
                  management, which will further help you in your professional life.
                </p>


                <h3>
                  Become a Guide, a Navig8r in Your Industry / Sector
                </h3>

                <p className={css.stepText}>
                  When you apply to be a mentor, your insights and guidance will
                  not only shape careers but also influence the direction and innovation
                  within your field. Becoming a career mentor allows you to help professionals
                  understand the complexities of their chosen field.
                </p>
                <p className={css.stepText}>
                  You can provide your mentees tailored strategies that newcomers might
                  otherwise struggle with. Your guidance might include personal success
                  stories as well as industry nuances, trends and the unwritten rules
                  that you live by day to day.
                </p>
                <p className={css.stepText}>
                  By sharing the intricacies and inner workings of your career,
                  you’ll enable your mentees to gain clarity, make informed decisions,
                  and seek out innovative approaches. Choosing to become a mentor
                  within your industry isn't just about giving information; it's
                  about shaping the future and preparing a new generation of knowledgeable,
                  well-equipped professionals ready to drive positive change.
                </p>

                <h3>
                  Boost Your Professional Development
                </h3>

                <p className={css.stepText}>
                  They say that teaching is learning twice. When you become a mentor,
                  it gives you a chance to deepen your knowledge, refine your communication
                  skills and remain in tune with the evolving landscape of your industry.
                </p>
                <p className={css.stepText}>
                  Mentoring also allows you to maintain a better understanding of what is
                  happening in your career space. This encourages continuous learning,
                  which enables you to provide relevant guidance to your mentees. When
                  you explain concepts to mentees, it’s likely to prompt fresh perspectives,
                  challenging you to reassess and enhance your own understanding.
                </p>
                <p className={css.stepText}>
                  When you become a career mentor, it will also help you broaden your own
                  network as you connect with diverse talents, perspectives and ways of
                  doing things that may expand your own thought processes. Guiding others
                  often leads to self-reflection, which will enable you to refine your
                  leadership style, adapt to new challenges, and strengthen your
                  problem-solving abilities.
                </p>
                <p className={css.stepText}>
                  Ultimately, online mentoring is a great way to enhance your own personal growth.
                  It ensures that you remain agile, informed and continuously progressing in
                  your own professional journey.
                </p>

                <h3>
                  Develop Lasting Connections & Networks
                </h3>

                <p className={css.stepText}>
                  Mentorship allows you to create long-lasting connections and a more
                  expansive network, all whilst making a side income. When you become a mentor,
                  you develop meaningful relationships with mentees, built on trust, respect
                  and shared aspirations.
                </p>
                <p className={css.stepText}>
                  These connections extend beyond mentorship; they have the potential to
                  evolve into mutually beneficial relationships that foster collaboration,
                  partnership and opportunity.
                </p>
                <p className={css.stepText}>
                  Moreover, a mentorship platform will introduce you to a more diverse
                  range of people within your industry, giving you a chance to grow your
                  own network. These connections open doors to potential collaborations
                  that can significantly impact your professional journey.
                </p>
                <p className={css.stepText}>
                  The network that you create through online mentorship is a valuable asset,
                  offering support, advice and opportunities for growth throughout your
                  career.
                </p>

                <h3>
                  Monetise Your Expertise & Skills
                </h3>

                <p className={css.stepText}>
                  Beyond personal satisfaction, online mentorship can also be
                  financially rewarding. Choosing to become a career mentor as
                  a side hustle offers a fulfilling and flexible way to leverage
                  your expertise while supplementing your income.
                </p>
                <p className={css.stepText}>
                  It allows you to share your knowledge and guide others at your
                  own pace, fitting seamlessly into your schedule. Whether you’ve
                  chosen to become a mentor for a few hours a week or you’re
                  dedicating time during weekends, this flexible engagement
                  lets you shape careers while maintaining your primary professional
                  commitments.
                </p>
                <p className={css.stepText}>
                  As a side hustle, career mentoring is an opportunity to make a
                  meaningful impact while pursuing your other passions.
                  You can balance your career while guiding aspiring
                  professionals toward success within your industry.
                </p>

                <h2 className={css.stepSubSubTitle}>
                  How to Set Up Your Account
                </h2>

                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    <ul className={css.diskList}>
                      <li>
                        Click on “Be a Mentor”
                      </li>
                      <li>
                        Provide your name, email and password
                      </li>
                      <li>
                        Set up your profile with a picture and brief highlights of your career
                      </li>
                      <li>
                        Mention the job role or roles you’d like to be a Navig8r for
                      </li>
                      <li>
                        Provide details like job experience and specific skills
                      </li>
                      <li>
                        Define your availability, cost per hour and payment details
                      </li>
                      <li>
                        Let our platform manage the rest - emails, reminders, payment, online sessions
                      </li>
                    </ul>

                    <p className={css.stepText}>
                      If you’re ready to find your mentees, apply to be a mentor now!
                    </p>

                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage} />
                  </p>
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage3"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentee',
                    }}
                    className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
                    title="Start mentoring"
                  >
                    Start mentoring
                  </NamedLink>
                </div>


                <h2 className={css.stepSubSubTitle}>
                  Apply to Be A Mentor at Career Navig8r Today!
                </h2>
                <p className={css.stepText}>
                  When choosing a career or growing within it, it’s not uncommon for people to
                  feel lost and apprehensive. Picking a career path is a major life decision,
                  which is why it requires careful consideration. Moreover, for those who have
                  just begun their career in a new industry, the path to success can seem
                  long and confusing.
                </p>
                <p className={css.stepText}>
                  Such individuals usually have numerous questions such as what to expect,
                  which skills are needed, and what are employers looking for when hiring.
                  This is where they need the help of a qualified and experienced mentor.
                </p>
                <p className={css.stepText}>
                  Whether you’re early on in your own career or a seasoned veteran, you have
                  the opportunity to offer your knowledge and expertise to these individuals.
                  You can help them understand what the job entails, which skills they need
                  to work on and what are the challenges they may face.
                </p>
                <p className={css.stepText}>
                  Want to become a career mentor? Sign up with our mentorship platform and
                  help shape the future of your industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentorTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentorTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentorTop;
